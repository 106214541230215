.left-stencil {
    position: relative;
    overflow: visible;
    z-index: 1;
    background-color: #008076!important;
    box-shadow: 2px 2px 10px #09090959;

    .collapsed-button {
        position: absolute;
        right: -15px;
        top: 50%;
        color: aliceblue;
        width: 15px;
        background-color: #008076;
        height: 30px;
        border-radius: 0 10px 10px 0;
        line-height: 30px;
        cursor: pointer;
        box-shadow: 2px 2px 10px #09090959;
    }
}

.ant-collapse {
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    .ant-collapse-item {
        border-bottom: none;
        .ant-collapse-header {
            color: #fff;
            font-size: 14px;
            font-weight: 800;
            .ant-collapse-expand-icon {
                margin-inline-start: 0;
                padding-inline-end: 0;
            }
            .ant-collapse-header-text {
                line-height: 22px;
            }
        }
        .ant-collapse-content {
            .dnd-item {
                transition: 0.3s;
                border-radius: 5px;
                box-shadow: 3px 3px 5px 0px #33333340;
                cursor: move;
                margin: 10px auto;
                overflow: hidden;
                width: 50px;
                height: 50px;
                background-color: #fff;
                font-size: 30px;
                line-height: 50px;
                color: #007e74;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}