@keyframes animated-line {
    to {
      stroke-dashoffset: -1000;
    }
  }
  
  // selection
  .x6-node-selected rect {
    stroke: #239edd;
  }
  
  .x6-edge-selected {
    path:nth-child(2) {
      stroke: #239edd;
      stroke-width: 1.5px;
    }
  }
  
  // snapline
  .x6-widget-snapline-horizontal,
  .x6-widget-snapline-vertical {
    stroke: #239edd;
  }
  
  // transform
  .x6-widget-transform {
    margin: -1px 0 0 -1px;
    padding: 0px;
    border: 1px solid #239edd;
  }
  .x6-widget-transform > div {
    border: 1px solid #239edd;
  }
  .x6-widget-transform > div:hover {
    background-color: #3dafe4;
  }
  .x6-widget-transform-active-handle {
    background-color: #3dafe4;
  }
  .x6-widget-transform-resize {
    border-radius: 0;
  }
  
  // control style
  .toolButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #e6e8eb;
    border-radius: 6px;
  
    button {
      min-width: 36px !important;
      height: 36px;
      border: 1px solid #fff;
      border-radius: 4px;
      background-color: #fff;
      outline: none;
      user-select: none;
      touch-action: manipulation;
      cursor: pointer;
  
      &:disabled {
        background-color: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.25);
      }
      &:hover {
        border: 1px solid #4096ff;
      }
    }
  
    .dropDownBtn {
      width: 100%;
      padding: 0;
    }
  
    .tippy-box {
      border: none !important;
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
      background-color: #ffffff;
    }
  
    .tippy-content {
      width: 60px;
      padding: 4px !important;
    }
  
    .tippyBtnContent {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      button {
        width: 100%;
        min-width: 36px !important;
  
        &:hover {
          border: none !important;
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
  
  .toolbuttonVertical {
    flex-direction: column;
  
    button {
      min-width: 40px !important;
    }
  }
  // control style end
  