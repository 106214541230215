.logic-react-node {
  position: relative;
  .img {
    width: 60px;
    height: 60px;
  }
  .icon {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .edit-icon {
    position: absolute;
    top: -7px;
    left: -7px;
    cursor: pointer;
  }
}