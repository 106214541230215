.xflow-guide {
    width: 100%;
    height: 100vh;
    
    .x6-widget-minimap {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }

    .toolButton {
        transform: scale(0.8);
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .ant-float-btn-primary {
        .ant-float-btn-body {
            background-color: #007e74;
        }
    }
}
.x6-graph {
    &::-webkit-scrollbar {
        display: none;
    }
}

.x6-edge-selected path:nth-child(2) {
    stroke: rgb(72, 203, 164);
    stroke-width: 2px;
}

.x6-cell {
    .x6-port {
        display: none;
    }
    &:hover {
        .x6-port {
            display: block;
        }
    }
}

