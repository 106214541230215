.react-node {
    width: 200px;
    height: 60px;
    background-color: #007e74;
    overflow: hidden;
    border-radius: 7px;
    display: flex;
    border: 2px solid #007e74;
    box-shadow: 3px 3px 10px #9ac0bd;

    &:hover {
        .close {
            display: block;
        }
    }

    .close {
        display: none;
        position: absolute;
        font-size: 16px;
        color: red;
        top: -6px;
        right: -6px;
        background: #fff;
        border-radius: 50%;
        cursor: pointer;
    }

    .node-content {
        width: 100%;
        margin-left: 10px;
        background-color: #fff;
        display: flex;
        align-items: flex-start;
        padding-top: 8px;

        .icon {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 30px;
            color: #fff;
            background: #007e74;
            border-radius: 5px;
            margin: 0 10px;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .text {
            flex: 1;
            text-align: left;
            margin-right: 10px;

            .header {
                font-size: 15px;
                font-weight: 600;
                word-break: break-all;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .desc {
                font-size: 12px;
            }
        }

        .edit-icon {
            margin: 10px 5px 0 0;
            cursor: pointer;
        }
    }
}