.queryBuilder .ant-input {
    width: auto;
}

.ruleGroup-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    button {
        margin-left: 10px;
    }
    .ruleGroup-notToggle {
        margin: auto 0 auto 10px;
    }
}

.ruleGroup-body {
    .rule {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-bottom: 5px;
        input {
            margin-right: 10px;
        }
    }
}